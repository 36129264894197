import {
    UnstyledButton,
    UnstyledButtonProps,
    Group,
    Avatar,
    Text,
    createStyles,
    Menu
  } from '@mantine/core';
import { IconChevronRight, IconLogout2, IconSettings  } from '@tabler/icons-react';
import { useNavigate } from 'react-router-dom';
  
  const useStyles = createStyles((theme) => ({
    user: {
      display: 'block',
      width: '100%',
      padding: theme.spacing.md,
      color: theme.colorScheme === 'dark' ? theme.colors.dark[0] : theme.black,
  
      '&:hover': {
        backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[8] : theme.colors.gray[0],
      },
    },
  }));
  
  interface UserButtonProps extends UnstyledButtonProps {
    image: string;
    name: string;
    email: string;
    icon?: React.ReactNode;
  }

  export function UserButton({ image, name, email, icon, ...others }: UserButtonProps) {
    const { classes } = useStyles();
    const navigate = useNavigate();
    const handleLogout = () => {
      localStorage.removeItem('maker_token');
      localStorage.removeItem('maker_user');
      navigate('/');
    }

    return (
      <Menu position='right'>
        <Menu.Target>
          <UnstyledButton className={classes.user} {...others}>
            <Group>
              <Avatar src={image} radius="xl" />
              <div style={{ flex: 1 }}>
                <Text size="sm" weight={500}>
                  {name}
                </Text>
      
                <Text color="dimmed" size="xs">
                  {email}
                </Text>
              </div>
      
              {icon || <IconChevronRight size="0.9rem" stroke={1.5} />}
            </Group>
          </UnstyledButton>
        </Menu.Target>

        <Menu.Dropdown>
        <Menu.Label>Application</Menu.Label>
        <Menu.Item icon={<IconSettings size={14} />}>Paramètres</Menu.Item>

        <Menu.Divider />

        <Menu.Item icon={<IconLogout2 size={14} />} onClick={handleLogout}>Déconnexion</Menu.Item>
      </Menu.Dropdown>
      </Menu>
      
    );
  }