import { useState } from 'react';
import {
  AppShell,
  useMantineTheme,
} from '@mantine/core';
import { HeaderSimple } from './Header';
import { Outlet
 } from 'react-router-dom';
import NavbarSimple from './Navbar';

export default function Layout() {
  const theme = useMantineTheme();
  return (
    <AppShell
      styles={{
        main: {
          background: theme.colorScheme === 'dark' ? theme.colors.dark[8] : theme.colors.gray[0],
        },
      }}
      navbarOffsetBreakpoint="sm"
      asideOffsetBreakpoint="sm"
      navbar={
        <NavbarSimple/>
      }
      header={
       <HeaderSimple/>
      }
    >
      <Outlet/>
    </AppShell>
  );
}