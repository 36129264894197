import { useState } from 'react';
import {  Header, Burger, Code, useMantineTheme, MediaQuery, Image, useMantineColorScheme, ActionIcon, Box } from '@mantine/core';
import { IconSun, IconMoonStars } from '@tabler/icons-react';

export function HeaderSimple() {

  const theme = useMantineTheme();
  const [opened, setOpened] = useState(false);
  const { colorScheme, toggleColorScheme } = useMantineColorScheme();
  const dark = colorScheme === 'dark';
  const src = colorScheme === 'dark' ? '../assets/logo-white.png' : '../assets/logo-black.png';


  return (
    <Header height={{ base: 50, md: 70 }} p="md" style={{ paddingLeft: '5rem', paddingRight: '5rem' }}>
    <div style={{ display: 'flex', alignItems: 'center', height: '100%', justifyContent: 'space-between' }}>
      <MediaQuery largerThan="sm" styles={{ display: 'none' }}>
        <Burger
          opened={opened}
          onClick={() => setOpened((o) => !o)}
          size="sm"
          color={theme.colors.gray[6]}
          mr="xl"
        />
      </MediaQuery>

      <Image maw={80} src={src} alt="" />

      <Box style={{ display: 'flex', alignItems: 'center' }}>
        <ActionIcon
            variant="outline"
            color={dark ? 'yellow' : 'blue'}
            onClick={() => toggleColorScheme()}
            title="Toggle color scheme"
            style={{ marginRight: '1rem' }}
        >
            {dark ? <IconSun size="1.1rem" /> : <IconMoonStars size="1.1rem" />}
        </ActionIcon>
        <Code style={{fontWeight: '800'}}>v1.0.0</Code>

      </Box>
      

    </div>
  </Header>
  );
}