import { Outlet, Navigate } from 'react-router-dom'
import { useState } from 'react'
import axios from 'axios'
export default function AdminRoutes() {

    const token = localStorage.getItem('maker_token');

    const [admin, setAdmin] = useState(true);

    const request = axios.get(process.env.REACT_APP_API_URL + '/check_admin', {
            headers:{
                'Authorization': `Bearer ${token}`
            }
    })
    .then((res) => {
        if(res.data === true){
            setAdmin(true);
        }else{
            setAdmin(false);
        }

    })
    .catch((err) => {
        setAdmin(false);
    })


    return admin ? <Outlet /> : <Navigate to="/" />
}
