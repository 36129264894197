import { Outlet, Navigate } from 'react-router-dom'
import { useState } from 'react'
import axios from 'axios'
export default function ProtectedRoutes() {

    const token = localStorage.getItem('maker_token');

    const [logged, setLogged] = useState(true);

    const request = axios.get(process.env.REACT_APP_API_URL + '/check_token', {
            headers:{
                'Authorization': `Bearer ${token}`
            }
    })
    .then((res) => {
        setLogged(true);
    })
    .catch((err) => {
        setLogged(false);
    })


    return logged ? <Outlet /> : <Navigate to="/" />
}
