import { Navbar, ScrollArea, createStyles, rem } from '@mantine/core';
import {
  IconGauge,
  IconHelpCircle,
  IconFileImport,
  IconUser,
  IconBrandWordpress,
  IconAlbum
} from '@tabler/icons-react';
import { UserButton } from './UserButton';
import { LinksGroup } from './NavbarLinksGroup';
import { useState, useEffect } from 'react';

// limit : ['user'] Seulement pour les users
// limit : ['admin'] Seulement pour les admins

const data = [
  { label: 'Dashboard', icon: IconGauge, mainLink: '/dashboard' },
  { label: 'Importation', icon: IconFileImport, mainLink: '/import' },
  { label: 'Tutoriels', icon: IconHelpCircle, mainLink: '/tutorials', limit : ['admin'] },
  { label: 'Tutoriels', icon: IconHelpCircle, initiallyOpened: false, 
    links: [
      { label: 'Liste', link: '/tutorials' },
      { label: 'Ajouter', link: '/add/tutorials' },
    ], limit : ['user'] 
  },
  {
    label: 'Utilisateurs',
    icon: IconUser,
    mainLink: '/users',
    limit: ['user']
  },
  { label: 'Sites', icon: IconBrandWordpress, mainLink: '/sites', limit: ['user'] },
  { label: 'Stockage', icon: IconAlbum, mainLink: '/stockage'},
];

const useStyles = createStyles((theme) => ({
  navbar: {
    backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.white,
    paddingBottom: 0,
  },

  header: {
    padding: theme.spacing.md,
    paddingTop: 0,
    marginLeft: `calc(${theme.spacing.md} * -1)`,
    marginRight: `calc(${theme.spacing.md} * -1)`,
    color: theme.colorScheme === 'dark' ? theme.white : theme.black,
    borderBottom: `${rem(1)} solid ${
      theme.colorScheme === 'dark' ? theme.colors.dark[4] : theme.colors.gray[3]
    }`,
  },


  links: {
    marginLeft: `calc(${theme.spacing.md} * -1)`,
    marginRight: `calc(${theme.spacing.md} * -1)`,
  },

  linksInner: {
    paddingTop: theme.spacing.xl,
    paddingBottom: theme.spacing.xl,
  },

  footer: {
    marginLeft: `calc(${theme.spacing.md} * -1)`,
    marginRight: `calc(${theme.spacing.md} * -1)`,
    borderTop: `${rem(1)} solid ${
      theme.colorScheme === 'dark' ? theme.colors.dark[4] : theme.colors.gray[3]
    }`,
  },
}));

export default function NavbarSimple() {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const { classes } = useStyles();
  const links = data.map((item, index) => <LinksGroup {...item} key={index} index={index} mainLink={item.mainLink} />);
  
    useEffect(() => {
        const user = localStorage.getItem('maker_user')?.split(',');
        if( user ){
            setName(user[0] + ' ' + user[1]);
            setEmail(user[2]);
        }
    }, []); 

  return (
    <Navbar width={{ sm: 300 }} p="md" className={classes.navbar}>

      <Navbar.Section grow className={classes.links} component={ScrollArea}>
        <div className={classes.linksInner}>{links}</div>
      </Navbar.Section>

      <Navbar.Section className={classes.footer}>
        <UserButton
          image="../assets/icon.png"
          name={name}
          email={email}
        />
      </Navbar.Section>
    </Navbar>
  );
}