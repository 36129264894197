import { MantineProvider } from '@mantine/core';
import { ColorSchemeProvider, ColorScheme } from '@mantine/core';
import { useHotkeys, useLocalStorage  } from '@mantine/hooks';
import Router from './components/Router';
import { Notifications } from '@mantine/notifications';
import { IconBrandYoutube, IconDashboard, IconFileText, IconSearch } from '@tabler/icons-react';
import { SpotlightAction, SpotlightProvider } from '@mantine/spotlight';

const actions: SpotlightAction[] = [
  {
    title: 'Rick Roll',
    description: 'Do not click 🙃',
    onTrigger: () => {
      window.open('https://www.youtube.com/watch?v=dQw4w9WgXcQ', '_blank')
    },
    icon: <IconBrandYoutube size="1.2rem" />,
  },
];

export default function App() {

  const [colorScheme, setColorScheme] = useLocalStorage<ColorScheme>({
    key: 'mantine-color-scheme',
    defaultValue: 'dark',
    getInitialValueInEffect: true,
  });

  

  const toggleColorScheme = (value?: ColorScheme) =>
    setColorScheme(value || (colorScheme === 'dark' ? 'light' : 'dark'));

  useHotkeys([['Ctrl+L', () => toggleColorScheme()]]);
  useHotkeys([['Mod + L', () => toggleColorScheme()]]);

  return (
    <ColorSchemeProvider colorScheme={colorScheme} toggleColorScheme={toggleColorScheme}>
      <MantineProvider theme={{ colorScheme }} withGlobalStyles withNormalizeCSS>
        <SpotlightProvider
          actions={actions}
          searchIcon={<IconSearch size="1.2rem" />}
          searchPlaceholder="Search..."
          shortcut={["ctrl + shift + p", "mod + shift + p"]}
          nothingFoundMessage="Nothing found..."
        >
          <Notifications/>
          <Router/>
        </SpotlightProvider>
      </MantineProvider>
    </ColorSchemeProvider>
  );
}


