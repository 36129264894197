import { Suspense, lazy } from 'react';
import {Route, BrowserRouter, Routes, useNavigate} from 'react-router-dom';
import { LoadingOverlay } from '@mantine/core';
import Logger from '../pages/Logger';
import ProtectedRoutes from './ProtectedRoutes';
import AdminRoutes from './AdminRoutes';
import Layout from './Layout';

const Error404 = lazy(() => import('../pages/Error404'));
const Dashboard = lazy(() => import('../pages/Dashboard'));
const Import = lazy(() => import('../pages/Import'));
const Tutorials = lazy(() => import('../pages/Tutorials'));
const Users = lazy(() => import('../pages/Users'));
const Sites = lazy(() => import('../pages/Sites'));
const ResetPassword = lazy(() => import('../pages/ResetPassword'));
const TutorialDetails = lazy(() => import('../pages/TutorialDetails'));
const AddTutorial = lazy(() => import('../pages/AddTutorial'));
const EditTutorial = lazy(() => import('../pages/EditTutorial'));
const Stockage = lazy(() => import('../pages/Stockage'));

export default function Router() {


  return (
        <BrowserRouter>
          <Suspense fallback={<LoadingOverlay visible={true} overlayBlur={2} />}>
            <Routes>
              <Route path="/" element={<Logger/>} />
              <Route path="/reset_password" element={<ResetPassword/>} />
              <Route element={<Layout/>}>
                {/* Accessible pour les admins */}
                <Route element={<AdminRoutes/>}>
                  <Route path="/users" element={<Users/>} />
                  <Route path="/sites" element={<Sites/>} />
                  <Route path="/add/tutorials" element={<AddTutorial/>} />
                  <Route path="/tutorials/:slug/edit" element={<EditTutorial/>} />
                </Route>

                {/* Accessible pour les utilisateurs connectés */}
                <Route element={<ProtectedRoutes/>}>
                  
                  <Route path="/dashboard" element={<Dashboard/>} />
                  <Route path="/import" element={<Import/>} />
                  <Route path="/tutorials" element={<Tutorials/>} />
                  <Route path="/tutorials/:slug" element={<TutorialDetails/>} />
                  <Route path='/stockage' element={<Stockage/>} />
                </Route>
              </Route>

              <Route path="*" element={<Error404 />} />
            </Routes>
          </Suspense>
        </BrowserRouter>

  );
}