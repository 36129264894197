import {
  TextInput,
  PasswordInput,
  Anchor,
  Paper,
  Title,
  Container,
  Group,
  Button,
  Image,
  ColorScheme,
  Collapse
} from '@mantine/core';
import { useLocalStorage, useToggle } from '@mantine/hooks';
import axios from 'axios';
import { useForm } from '@mantine/form';
import { notifications } from '@mantine/notifications';
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from 'react';

export default function Logger() {
  const [type, toggle] = useToggle(['login', 'forgot']);
  const [description, setDescription] = useState('')
  const [loading, setLoading] = useState(false)
  const colorScheme = useLocalStorage<ColorScheme>({
    key: 'mantine-color-scheme',
  });
  const navigate = useNavigate();

  const src = colorScheme[0] === 'dark' ? '../assets/logo-white.png' : '../assets/logo-black.png';

  const form = useForm({
    initialValues: {
      email: '',
      password: '',
    },

    validate: {
      email: (value) => (/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value) ? null : 'E-mail invalide'),
      password: (value) => ( type === 'login' ? (value.length === 0 ? 'Veuillez saisir un mot de passe' : null) : null),
    },
  });

  const handleSubmit = (values : any): void => {
    console.log(type)
    if(type === 'login') {

      axios.post(process.env.REACT_APP_API_URL + '/login', values)
      .then(res => {
        const token = res.data.token
        const user = res.data.user
        localStorage.setItem('maker_token', token)
        localStorage.setItem('maker_user', user)
        navigate('/dashboard')
      })
      .catch(err => {
        console.log(err)
        const msg = err.response.data.msg
  
        notifications.show({
          id: 'login-error',
          withCloseButton: true,
          autoClose: 5000,
          title: "Echec de l'authentification",
          message: msg,
          color: 'red',
        });
      })
      
    }else{
      setLoading(true)
      axios.post(process.env.REACT_APP_API_URL + '/forgot_password', values)
      .then(res => {
        setLoading(false)
        notifications.show({
          id: 'forgot-success',
          withCloseButton: true,
          autoClose: 5000,
          title: "Demande envoyée",
          message: res.data.msg,
          color: 'green',
      });
      })
      .catch(err => {
        console.log(err)
        setLoading(false)
        const msg = err.response.data.msg

        notifications.show({
          id: 'forgot-error',
          withCloseButton: true,
          autoClose: 5000,
          title: "Echec",
          message: msg,
          color: 'red',
        });
      })

    }
    
  };

  const handleChangeType = () => {
    if(type === 'forgot') {
      setDescription('')
    } else {
      setDescription('Un mail pour réinitialiser votre mot de passe vous sera envoyé')
    }
    toggle();
  }

  useEffect(() => {

    const params = new URLSearchParams(window.location.search);
    const url = new URL(window.location.href);

    if(params.has('access_token')){

      var token = params.get('access_token');
      
      axios.post(process.env.REACT_APP_API_URL + '/check_access_token', {
        token
      }).then(res => {
        const token = res.data.token
        const user = res.data.user
        localStorage.setItem('maker_token', token)
        localStorage.setItem('maker_user', user)
        navigate('/dashboard')
      }).catch(err => {
        params.delete('access_token');
        url.search = params.toString();
        window.history.pushState({}, '', url.toString());
        notifications.show({
          id: 'login-error',
          withCloseButton: true,
          autoClose: 5000,
          title: "Echec",
          message: 'Token d\'acces invalide',
          color: 'red',
        })
      })
      
    }

    
  })


  return (
    <Container size={420} my={40}>
      <Image maw={150} mx="auto" src={src} alt="" />
      <Title
        align="center"
        mt={25}
        sx={(theme) => ({ fontFamily: `Greycliff CF, ${theme.fontFamily}`, fontWeight: 900 })}
      >
        Hub Maker
      </Title>

      <Paper withBorder shadow="md" p={30} mt={30} radius="md">
        <form onSubmit={form.onSubmit(handleSubmit)}>
          <TextInput label="Email" name='email' placeholder="you@com-maker.com" required  {...form.getInputProps('email')} description={description}/>
          <Collapse in={type === 'login'}>
            <PasswordInput label="Mot de passe" placeholder="Mot de passe" withAsterisk mt="md"  {...form.getInputProps('password')}/>
          </Collapse>
          <Group position="apart" mt="lg">
            <Anchor onClick={handleChangeType} component="button" size="sm">
              {type === 'login' ? 'Mot de passe oublié ?' : 'Se connecter'}
            </Anchor>
          </Group>
          <Button fullWidth mt="xl" type="submit" loading={loading}>
            {type === 'login' ? 'Se connecter' : 'Réinitialiser'}
          </Button>

        </form>
        
      </Paper>
    </Container>
  );
}